<template>
	<div id="about">
		<section id="heroContent">
			<div id="heroText">
				<h1 class="main1">About</h1>
				<h1 class="main2">Bitfluent</h1>
				<h3 class="heroHeadline">
					A new Minnesota-based business, out to make the web a better place.
				</h3>
			</div>
			<div id="heroImgCont">
				<img src="@/assets/programmingHero.svg" id="heroImg" />
			</div>
		</section>
		<section id="bulletPoints">
			<div class="bulletCard">
				<div class="textCont">
					<h3 class="bulletHeader">Who is Bitfluent LLC?</h3>
					<p>
						Bitfluent LLC is an independent Minneapolis-based business owned and
						operated by Adam T Morgan. Our services include: UI/UX design, web
						frontend/client development, backend server-side development, deployment,
						and site maintenance.
					</p>
				</div>
			</div>
			<div class="bulletCard">
				<div class="textCont">
					<h3 class="bulletHeader">We Love the Internet</h3>
					<p>
						We create modern, fluid web experiences that are a joy to use. UI/UX
						design to full stack development, we have the whole thing covered to a
						quality standard.
					</p>
				</div>
			</div>
			<div class="bulletCard">
				<div class="textCont">
					<h3 class="bulletHeader">Modern Problems Require Modern Solutions</h3>
					<p>
						We embrace the cutting-edge of web technology, using the latest JavaScript
						features and frameworks like Vue 3. For the server/backend, Node JS is our
						weapon of choice.
					</p>
				</div>
			</div>
		</section>
		<section id="findOutMore">
			<div @click="smoothScrollTop()">
				<router-link class="continue moreButton" :to="{ name: 'Portfolio' }"
					>See Our Work</router-link
				>
			</div>
			<div @click="smoothScrollTop()">
				<router-link class="continue portButton" :to="{ name: 'Contact' }"
					>Say Hi (Contact Us)</router-link
				>
			</div>
		</section>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';
import { smoothScrollTop } from '@/plugins/utilities';

// Component Imports

export default {
	name: 'Home',
	components: {},

	setup() {
		// Injections

		// Setup Returns
		return {
			smoothScrollTop,
		};
	},
};
</script>

<style scoped>
#about {
	border: solid yellow 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	/* z-index: 10; */
}
#heroContent {
	height: auto;
	width: 100%;
	margin: 0px;
	position: relative;
	z-index: 0;
	border: solid green 0px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 0;
}
#heroText {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: flex-start;
	border: solid green 0px;
	padding: 0px 0px 0px 40px;
	margin: 30px 0px 30px 0px;
	border: solid blue 0px;
	max-width: 510px;
	width: 100%;
}

#bulletPoints {
	border: solid blue 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0px 0px 0px 0px;
	padding: 20px 0px 0px 0px;
}

.bulletCard {
	width: 100%;
	border: solid blue 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 80px 0px 0px 0px;
}
/* 
.bulletCard:nth-child(2) {
	flex-direction: row-reverse;
} */

.textCont {
	max-width: 800px;
	border: solid red 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin: 20px 40px 20px 40px;
	color: var(--text-color);
}

.bulletHeader {
	font-size: clamp(20px, 16vw, 38px);
	text-align: left;
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
	margin: 0px 0px 10px 0px;
}

p {
	text-align: left;
	font-size: 18px;
}

#findOutMore {
	width: 100%;
	height: auto;
	min-height: 150px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 100px 0px 50px 0px;
}

.continue {
	width: 280px;
	height: 60px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	margin: 20px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
}

.continue:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.continue:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.moreButton {
	background-position: 0px 0px;
}

.portButton {
	background-position: 300px;
}

h1 {
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 26px;
	margin: -10px 0px -10px 0px;
	border: solid yellow 0px;
}

h1.main1 {
	font-size: clamp(10px, 11vw, 60px);
}

h1.main2 {
	font-size: clamp(20px, 16vw, 90px);
}

h3.heroHeadline {
	font-size: clamp(8px, 6vw, 24px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 18px;
	border: solid yellow 0px;
	width: 90%;
	max-width: 500px;
	text-align: left;
	font-weight: 500;
	margin: 10px 0px 0px 0px;
}

#heroImg {
	width: 100vw;
	max-width: 570px;
	height: auto;
	margin: 0px -50px 0px -50px;
	border: solid blue 0px;
	animation: heroImageBounce 10000ms ease-in-out infinite;
}

#heroImgCont {
	border: solid yellow 0px;
}

/* Animations */

@keyframes heroImageBounce {
	0% {
		transform: translateY(-4px) rotate(0.5deg);
	}
	50% {
		transform: translateY(4px) rotate(-0.5deg);
	}
	100% {
		transform: translateY(-4px) rotate(0.5deg);
	}
}

@media only screen and (max-width: 800px) {
}
</style>
